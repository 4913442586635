.main-content {
  position: relative;

  // Navbar
  .navbar-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // Container
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
      padding-right: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
    }
  }
}

.home-content {
  @media only screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.main-content-home {
  @media only screen and (max-width: 768px) {
    grid-column: span 4 / span 4;
  }

  // @media only screen and (max-width: 576px) {
  //   margin-left: 0 !important;
  //   margin-left: 0px !important;
  //   flex-grow: 1;
  //   max-width: 21rem;
  // }

  // @media only screen and (max-width: 427px) {
  //   max-width: 20rem;
  // }

  // @media only screen and (max-width: 411px) {
  //   max-width: 18rem;
  // }
}

.mt-90 {
  margin-top: 90px;
}

.landing-banner {
  padding-top: 0;
  //margin-left: 250px;
  margin-top: 100px;
  .card {
    border-radius: 0;
  }

  @media only screen and (max-width: 576px) {
    margin-left: 0;
    margin-top: 23%;
  }

  // @media only screen and (max-width: 425px) {
  //   margin-top: 26%;
  // }

  @media only screen and (max-width: 375px) {
    margin-top: 30%;
  }
}

// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {
  @each $breakpoint, $dimension in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {
        // Left
        &.fixed-left + .main-content {
          margin-left: $navbar-vertical-width;
        } // Right
        &.fixed-right + .main-content {
          margin-right: $navbar-vertical-width;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #d61313;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
