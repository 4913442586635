//
// Card
//

.w-fit-content {
  width: fit-content;
}

.card-translucent {
  background-color: rgba(18, 91, 152, 0.08);
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 576px) {
  // .text-sm-center {
  //   text-align: center !important;
  // }
}
