//
// Input group
//

@import "forms/input-group";

.home-input {
  background: linear-gradient(180deg, #161514 0%, #2b2925 40.02%);
  border: 3px solid #000000;
  font-weight: 500;
  padding-left: 1.3rem;
}

.validation-bg {
  background: #707070;
  border: 3px solid #000000;
  color: #231b0e;
  font-weight: 500;
  text-align: center;
  padding: 6px;
  border-radius: 0 0.375rem 0.375rem 0;
  width: 20%;
  justify-content: center;
}
