//
// Vertical navbar
//

// Vertical
//
// Creates a vertically aligned version of the navbar
.navbar-vertical {
  box-shadow: $navbar-vertical-box-shadow;

  .navbar {
    border-width: 0 0 1px 0;
    border-style: solid;
  }

  // Navbar light

  .navbar-light {
    background-color: $navbar-light-bg;
    border-color: $navbar-light-border-color;
  }

  // Navbar brand

  .navbar-brand {
    margin-right: 0;
  }

  .navbar-brand-img,
  .navbar-brand > img {
    max-width: 100%;
    max-height: 2rem;
  }

  // Navbar collapse

  @include media-breakpoint-up(md) {
    .navbar-collapse {
      margin-left: -$navbar-padding-x;
      margin-right: -$navbar-padding-x;
      padding-left: $navbar-padding-x;
      padding-right: $navbar-padding-x;

      &:before {
        content: "";
        display: block;
        margin: $navbar-padding-y (-$navbar-padding-x);
      }
    }
  }

  // Navbar navigaton
  .navbar-nav {
    margin-left: -$navbar-padding-x;
    margin-right: -$navbar-padding-x;

    // Navbar link

    .nav-link {
      padding-left: $navbar-padding-x;
      padding-right: $navbar-padding-x;
      font-size: $navbar-nav-link-font-size;

      &.active {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: $nav-link-padding-y;
          bottom: $nav-link-padding-y;
          border-left: 2px solid $primary;
        }

        .nav-home-btn {
          background: radial-gradient(
            83.38% 83.38% at 50% 9.89%,
            #fdf5d8 0%,
            #f8d96f 100%
          );
          box-shadow: inset inset 3px 3px 15px #ebaa18;
          color: #663800;
        }

        .hot-nav {
          background: radial-gradient(
            83.38% 83.38% at 50% 9.89%,
            #fdf5d8 0%,
            #f8d96f 100%
          ) !important;
          box-shadow: inset inset 3px 3px 15px #ebaa18;
          color: #ff2626;
        }
      }

      // Icon

      > i {
        min-width: $navbar-icon-min-width;
        font-size: 0.9375rem;
        line-height: ($font-size-base * $line-height-base);
      }

      // Dropdown

      .dropdown-menu {
        border: none;

        .dropdown-menu {
          margin-left: $dropdown-item-padding-x / 2;
        }
      }
    }
  }

  // Navbar navigation
  .navbar-nav .nav-link {
    display: flex;
    align-items: center;
  }

  .navbar-nav .nav-link[data-toggle="collapse"] {
    &:after {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Free";
      font-weight: 700;
      content: "\f105";
      margin-left: auto;
      color: $text-muted;
      transition: $transition-base;
    } // Expanded
    &[aria-expanded="true"] {
      &:after {
        transform: rotate(90deg);
      }
    }
  }

  // Second level
  .navbar-nav .nav .nav-link {
    padding-left: $navbar-padding-x + $navbar-icon-min-width;
  }

  // Third level
  .navbar-nav .nav .nav .nav-link {
    padding-left: $navbar-padding-x * 1.5 + $navbar-icon-min-width;
  }

  // Navbar heading
  .navbar-heading {
    padding-top: $nav-link-padding-y;
    padding-bottom: $nav-link-padding-y;
    font-size: $font-size-xs;
    text-transform: uppercase;
    letter-spacing: 0.04em;
  }

  // Expanded navbar specific styles
  &.navbar-expand {
    @each $breakpoint, $dimension in $grid-breakpoints {
      &-#{$breakpoint} {
        @include media-breakpoint-up(#{$breakpoint}) {
          display: block;
          position: fixed;
          width: 100%;
          max-width: $navbar-vertical-width;
          padding-left: $navbar-vertical-padding-x;
          padding-right: $navbar-vertical-padding-x;

          overflow-y: auto;

          // Container
          > [class*="container"] {
            flex-direction: column;
            align-items: stretch;
            min-height: 100%;
            padding-left: 0;
            padding-right: 0; // Target IE 10 & 11
            @media all and (-ms-high-contrast: none),
              (-ms-high-contrast: active) {
              min-height: none;
              height: 100%;
            }
          }

          // Fixes the vertical navbar to the left
          &.fixed-left {
            left: 0;
            border-width: 0 1px 0 0;
          }

          // Fixed the vertical navbar to the right
          &.fixed-right {
            right: 0;
            border-width: 0 0 0 1px;
          }

          // Navbar collapse
          .navbar-collapse {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            margin-left: -$navbar-vertical-padding-x;
            margin-right: -$navbar-vertical-padding-x;
            padding-left: $navbar-vertical-padding-x;
            padding-right: $navbar-vertical-padding-x;

            > * {
              min-width: 100%;
            }
          }

          // Navbar navigation
          .navbar-nav {
            flex-direction: column;
            margin-left: -$navbar-vertical-padding-x;
            margin-right: -$navbar-vertical-padding-x;
          }

          .navbar-nav .nav-link {
            padding: $navbar-vertical-nav-link-padding-y
              $navbar-vertical-nav-link-padding-x;

            &.active {
              &:before {
                top: $nav-link-padding-y;
                bottom: $nav-link-padding-y;
                left: 0;
                right: auto;
                border-left: 2px solid #2a7bc0;
                border-bottom: 0;
              }
            }
          }

          // Second level
          .navbar-nav .nav .nav-link {
            padding-left: $navbar-vertical-padding-x + $navbar-icon-min-width;
          }

          // Third level
          .navbar-nav .nav .nav .nav-link {
            padding-left: $navbar-vertical-padding-x * 1.5 +
              $navbar-icon-min-width;
          }

          // Navbar brand
          .navbar-brand {
            display: block;
            text-align: center;
            padding-top: (2rem - $navbar-padding-y);
            padding-bottom: (2rem - $navbar-padding-y);
          }

          .navbar-brand-img {
            max-height: 2.5rem;
          }

          // Navbar user
          .navbar-user {
            margin-left: -$navbar-vertical-padding-x;
            margin-right: -$navbar-vertical-padding-x;
            padding-top: $spacer;
            padding-bottom: $spacer - $navbar-padding-y;
            padding-left: $navbar-vertical-padding-x;
            padding-right: $navbar-vertical-padding-x;
            border-top: 1px solid $border-color;

            // Dropup menu
            .dropup .dropdown-menu {
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
}

// home styles
.w-top-bottom {
  top: 0;
  bottom: 0;
}

.bg-theme-delux {
  background-color: #1b1812;
}

.navbar-vertical-home {
  margin-top: 6rem;
  padding-top: 2rem;
  padding-left: 8px;
  padding-right: 8px;

  .container-home {
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }

  @media only screen and (max-width: 768px) {
    grid-column: span 1 / span 1;
    display: flex;
    align-items: flex-start;
    padding-top: 1rem;
  }

  @media only screen and (max-width: 576px) {
    margin-top: 5rem;
  }
}

.nav-link-home {
  padding: 3px 0px !important;

  &::before {
    border-left: 0 !important;
  }

  @media only screen and (max-width: 576px) {
    // max-width: 6rem !important;
    align-items: flex-start;
  }
}

.nav-home-btn {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  border: 6px solid transparent;
  padding: 3px;

  border-style: solid;
  border-width: 8px;
  border-image: url("../../../../../assets/img/homepage/buttons/home-btn-border.png")
    15 round;
  border-radius: 18px;

  color: #f9db79;
  text-transform: uppercase;

  background: radial-gradient(
    79.09% 79.09% at 50% 14.18%,
    #4e4d4c 0%,
    #060606 100%
  );

  width: 100%;
  // max-height: 5rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    background: radial-gradient(
      83.38% 83.38% at 50% 9.89%,
      #fdf5d8 0%,
      #f8d96f 100%
    );
    box-shadow: inset 3px 3px 15px #ebaa18;
    color: #663800;
  }

  span {
    font-weight: 500;
    margin-left: 0.5rem;
    font-size: 16px;
  }

  img {
    width: 4rem;
  }

  @media only screen and (max-width: 992px) {
    span {
      font-size: 13px;
    }
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-color: #f6c725 !important;
  }

  @media only screen and (max-width: 576px) {
    border: 3px solid #fed74f;
    text-align: center;
    padding: 5px 10px;
    margin: 0px 5px;

    span {
      margin-left: 0;
    }

    img {
      width: 3rem;
    }
  }
}

.hot-nav {
  background: radial-gradient(
    79.09% 79.09% at 50% 14.18%,
    #ff8383 0%,
    #ff2626 100%
  ) !important;
  color: #fbde80;

  &:hover {
    background: radial-gradient(
      83.38% 83.38% at 50% 9.89%,
      #fdf5d8 0%,
      #f8d96f 100%
    ) !important;
    box-shadow: inset 3px 3px 15px #ebaa18;
    color: #ff2626;
  }
}
