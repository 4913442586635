//
// Typography
//

@import "type/type";
@import "type/heading";
@import "type/display";
@import "type/article";

.welcome-marquee {
  color: #fecd00;
  font-size: 1.3rem;
  padding: 0.2rem;
}

.gold-font {
  background: linear-gradient(
    93.24deg,
    #d09d43 8.58%,
    #fefcd0 32.09%,
    #b17a1a 58.48%,
    #fcebab 77.66%,
    #d2a649 100.7%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
