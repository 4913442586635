//
// Utilities
//

@import "utilities/backgrounds";
@import "utilities/floating";
@import "utilities/helper";
@import "utilities/image";
@import "utilities/opacity";
@import "utilities/overflow";
@import "utilities/position";
@import "utilities/sizing";
@import "utilities/spacing";
@import "utilities/shadows";
@import "utilities/text";
@import "utilities/transform";

.no-copy {
  user-select: none;
}

.w-sm-100 {
  @media only screen and (max-width: 576px) {
    width: 100% !important;
  }
}

.floating-image {
  position: fixed;
  bottom: 0;
}
