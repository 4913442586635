//
// Type
//

// Paragraphs

p {
  font-size: $paragraph-font-size;
  font-weight: $paragraph-font-weight;
  line-height: $paragraph-line-height;
}

.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
  line-height: $paragraph-line-height;
  margin-top: 1.5rem;

  + .btn-wrapper {
    margin-top: 3rem;
  }
}

.description {
  font-size: $font-size-sm;
}

.font-gold {
  color: #fbd01e;

  :hover {
    color: #f5d34a;
  }
}

.text-underline {
  text-decoration: underline;
}
