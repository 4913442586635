//
// Footer
//

@import "footers/footer";

.landing-page-footer {
  border-top: 3px solid #797979;
  background: radial-gradient(
    67.09% 67.31% at 49.96% 87.63%,
    #333436 0%,
    rgba(51, 52, 54, 0) 100%
  );

  .partners-card {
    background: #202020;
    border: 2px solid #000000;
    text-align: center;
    width: 14rem;
    padding: 0.5rem 1rem 0.5rem 1rem;

    img {
      max-height: 60px;
    }
  }

  @media only screen and (max-width: 768px) {
  }

  @media only screen and (max-width: 576px) {
    .partners-card {
      img {
        max-height: 40px;
      }

      padding: 5px 0;
      width: 100%;
    }
  }

  .footer-context {
    h2 {
      color: #fecd43;
    }
    p {
      color: white;
      font-weight: 500;
      margin-bottom: 0;
      cursor: pointer;
    }

    i {
      padding-right: 1rem;
    }
  }
}
