//
// Button
//

@import "buttons/button";
@import "buttons/button-icon";
@import "buttons/button-brand";

.home-login-btn {
  background: linear-gradient(
    105.12deg,
    #ba8326 12.35%,
    #fcf7c5 32.99%,
    #b27c1c 49.82%,
    #fffdd4 69.54%,
    #ddb65b 87.21%
  );
  border: 3px solid #000000;
  border-image-source: linear-gradient(96.33deg, #c09747 9%, #563906 86.67%);
  color: #221908;
  font-weight: 800;
  padding: 0.625rem 1.25rem;

  :hover {
    border: 3px solid #e2e837;
  }
}

.btn-gold {
  color: #221908;
  font-weight: 600;
  padding: 0.625rem 2.5rem;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    padding: 0.625rem 1.5rem;
  }

  @media only screen and (max-width: 576px) {
    padding: 0.625rem 1.5rem;
  }

  &:hover {
    animation-name: shine;
    animation-duration: 260ms;
  }

  position: relative;
  border: 3px solid transparent;
  border-radius: 6px;

  background: linear-gradient(
    105.12deg,
    #ba8326 12.35%,
    #fcf7c5 32.99%,
    #b27c1c 49.82%,
    #fffdd4 69.54%,
    #ddb65b 87.21%
  );

  /* just to show box-shadow still works fine */
  box-shadow: 0 3px 9px black, inset 0 0 9px white;

  &:after {
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -6px;
    border: 3px solid;

    background: linear-gradient(
      90deg,
      #ffde31 0%,
      #d7b04e 0.01%,
      #f6e897 32.29%,
      #bd8d22 68.75%,
      #dab552 100%
    );
    content: "";
    z-index: -1;
    border-radius: 6px;
  }
}

.btn-gold-outline {
  position: relative;
  display: inline-block;
  border: transparent;
  font-weight: 700;
  padding: 0.8rem 2.5rem;
  background: transparent !important;
  margin-left: 0.3rem;
  border-radius: 1rem;

  /* just to show box-shadow still works fine */
  box-shadow: 0 3px 9px black, inset 0 0 9px #161616;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 5px;
    padding: 4px; /* control the border thickness */
    background: linear-gradient(
      96.33deg,
      #fefecc 9%,
      #ae7b2a 32.87%,
      #fdfbd6 62.8%,
      #fffdc6 86.67%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    pointer-events: none;
  }

  // @media only screen and (max-width: 375px) {
  //   border: 3px solid #ffde31;
  // }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

// .outline::after {
//   position: absolute;
//   top: -4px;
//   bottom: -4px;
//   left: -4px;
//   right: -4px;
//   background: linear-gradient(
//     96.33deg,
//     #fefecc 9%,
//     #ae7b2a 32.87%,
//     #fdfbd6 62.8%,
//     #fffdc6 86.67%
//   );
//   content: "";
//   z-index: -1;
//   border-radius: 16px;
// }
.download-app-btn {
  padding: 0.625rem 1.5rem;
  position: relative;
  border: 3px solid transparent;
  border-radius: 6px;
  background: linear-gradient(
    89.95deg,
    #c69227 1.58%,
    #fdf3a5 18.17%,
    #b58113 49.91%,
    #fef09c 73.94%,
    #cb9c33 99.04%
  );
  background-clip: padding-box;
  border-color: #d7b04e;

  // for shine & gradient effects to work
  position: relative;
  overflow: hidden;

  /* just to show box-shadow still works fine */
  box-shadow: 0 3px 9px black, inset 0 0 9px white;

  animation: TransitioningBackground 10s ease infinite;
  // to ease the button growth on hover
  transition: 0.6s;

  // grow button and change background gradient on hover
  &:hover {
    background: linear-gradient(
      89.95deg,
      #c69227 1.58%,
      #fdf3a5 18.17%,
      #b58113 49.91%,
      #fef09c 73.94%,
      #cb9c33 99.04%
    );
    transform: scale(1.2);
    cursor: pointer;

    // psuedo-elements for right- and left-side shine animations
    &::before,
    &::after {
      transform: translateX(300px) skewX(-15deg);
      transition: 0.7s;
    }
  }

  // psuedo-element shine animation left side
  &::before {
    content: "";
    display: block;
    position: absolute;
    background: #f9f09f;
    width: 60px;
    height: 100%;
    top: 0;
    filter: blur(30px);
    transform: translateX(-100px) skewX(-15deg);
  }

  // psuedo-element shine animation right side
  &::after {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(
      90deg,
      #ffde31 0%,
      #d7b04e 0.01%,
      #f6e897 32.29%,
      #bd8d22 68.75%,
      #dab552 100%
    );
    width: 30px;
    height: 100%;
    top: 0;
    filter: blur(5px);
    transform: translateX(-100px) skewX(-15deg);
  }
}

.download-app-nav {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.d-app-btn {
  border-radius: 5px;
}
