//
// Cards
//

@import "cards/card";
@import "cards/card-profile";
@import "cards/card-animations";
@import "cards/card-stats";

.hover-gold {
  .card:hover {
    border: 1px solid #d9bb5d;
  }
}

.rounded-md {
  border-radius: 8px !important;
}

.account-balance {
  padding: 20px;

  .title {
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.3rem;
  }

  .content {
    font-weight: 700;
    font-size: 30px;
    line-height: 1.33;
    color: #fff;
  }
}

.bg-lighter {
  background-color: #e9ecef;
}

.features {
  text-align: left;

  .title {
    font-size: 1.25rem;
    margin-top: 0.4rem;
    color: #dcba13;
    margin-bottom: 0;
    font-weight: 600;
  }

  img {
    height: 5rem;
  }

  @media only screen and (max-width: 1024px) {
    img {
      height: 4rem;
    }
  }

  @media only screen and (max-width: 768px) {
    img {
      height: 3rem;
    }
  }

  @media only screen and (max-width: 576px) {
    text-align: center;

    img {
      height: 3rem;
    }

    .title {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

.hot-game-card {
  color: #fff;
  padding: 1rem;
  text-align: left;

  h1 {
    font-weight: 700;
  }

  img {
    margin-top: -6rem;
    width: 18rem;
    // width: 13rem;
  }

  .image {
    text-align: center;
  }
  @media only screen and (max-width: 1440px) {
    img {
      width: 15rem;
      margin-top: -3rem;
    }
  }

  @media only screen and (max-width: 1024px) {
    img {
      width: 13rem;
      margin-top: -2rem;
    }
  }
  @media only screen and (max-width: 768px) {
    img {
      width: 13rem;
      margin-left: -2rem;
    }
    h1 {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 576px) {
    img {
      width: 10rem;
      margin-left: 0;
    }
    h1 {
      text-align: center;
    }

    p {
      text-align: center;
    }
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.bg-hot-danger {
  background: linear-gradient(84.98deg, #d3085d 1.55%, #d21818 100%);
  border-radius: 18px;
  // max-height: 80%;
}

.bg-hot-dark {
  background: linear-gradient(
    95.17deg,
    #1a2025 1.02%,
    #374149 24.82%,
    #222428 48.45%,
    #3d464a 73.19%,
    #151b20 100%
  );
  border-radius: 18px;
  // max-height: 80%;
}

.bg-option {
  padding: 1.25rem;
  border: 1px solid #e6ecf1;
  background-color: #f5f7f9;
}

.bg-dark {
  background: radial-gradient(
    91.98% 150.91% at 49.95% -100.68%,
    #393d46 0%,
    #191e2c 100%
  );
  border: 1px solid #636363;
  border-radius: 20px;
}

.comming-soon-content {
  background: radial-gradient(
    73.45% 73.45% at 50% -23.01%,
    #2e364a 0%,
    #21293a 100%
  );
  border: 1px solid #636363;
  border-radius: 20px;
  padding-right: 1rem;
  padding-left: 1rem;

  img {
    height: 8rem;
    width: 8rem;
    float: left;
    margin: 10px 0;
  }

  h2 {
    font-size: 1.3rem;
    margin-bottom: 0;
  }

  p {
    font-weight: 500;
    font-size: 15px;
  }

  &:before {
    position: absolute;
    content: "Soon";
    color: black;
    background: #d3b151;
    right: 0;
    height: 35px;
    width: 60px;
    padding-top: 4px;
    text-align: center;
    border-radius: 0 18px 0 18px;
    font-weight: 500;
  }

  @media only screen and (max-width: 1440px) {
    img {
      height: 135px;
      width: 130px;
    }
    h2 {
      margin-top: 1.2rem;
      margin-bottom: 0;
      font-size: 25px;
    }

    &:before {
      height: 30px;
      width: 50px;
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 1024px) {
    img {
      height: 105px;
      width: 100px;
    }
    h2 {
      margin-top: 0.5rem;
      margin-bottom: 0;
      font-size: 25px;
    }

    &:before {
      height: 35px;
      width: 50px;
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 768px) {
    img {
      height: 95px;
      width: 90px;
    }
    h2 {
      margin-top: 0.5rem;
      margin-bottom: 0;
      font-size: 25px;
    }

    &:before {
      height: 35px;
      width: 50px;
      font-size: 15px;
    }
  }

  @media only screen and (max-width: 576px) {
    img {
      height: 80px;
      width: 75px;
    }
    h2 {
      margin-top: 1rem;
      margin-bottom: 0;
      font-size: 15px;
    }
    &:before {
      height: 30px;
      width: 45px;
      bottom: 0;
      border-radius: 18px 0 18px 0;
    }
  }
}
