iframe {
  border: 0;
  display: none;
}

figcaption,
figure,
main {
  display: block;
}

main {
  overflow: hidden;
}
