//
// Card with hover animations
//

.card-lift--hover {
  &:hover {
    transform: translateY(-20px);
    @include transition($transition-base);
  }
}

/* Style the counter cards */
.card-games {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }

  // @media only screen and (max-width: 1440px) {
  //   width: 11rem;
  //   border-radius: 5px;
  // }
  // @media only screen and (max-width: 1024px) {
  //   width: 10rem;
  //   border-radius: 5px;
  // }
  // @media only screen and (max-width: 768px) {
  //   width: 9rem;
  // }
  // @media only screen and (max-width: 576px) {
  //   img {
  //     height: 12rem;
  //   }
  // }

  .profile {
    border-radius: 5px;
    transition: 0.1s;
  }

  &:hover {
    .profile {
      transform: scale(1.4);
      border-top-right-radius: 50px;
      border-top-left-radius: 50px;
    }

    .content .from-bottom {
      top: 0%;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .back {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #2c2c2c9e;
      transition: 0.5s;

      z-index: 1;
      padding: 10px;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      h2 {
        color: #fff;
      }

      h3 {
        font-size: 15px;
        letter-spacing: 2px;
      }

      .tem-img {
        border-radius: 100%;
      }

      .des {
        font-size: 13px;
        padding: 20px 0px;
      }
    }
  }
}

.from-bottom {
  top: 100%;
  left: 0;
}
