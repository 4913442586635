//
// Modal
//

@import "modals/modal";

.bg-gold-theme {
  background: radial-gradient(
      82.05% 82.05% at 50% 17.95%,
      #c02b2b 0%,
      #191206 100%
    )
    0% 0% / cover;
}
